<template>
    <div class="error-box">
        <img src="@/assets/images/500.png">
    </div>
</template>
<script>
export default {
    name: "error500",
}
</script>
<style lang="less" scoped>

</style>
